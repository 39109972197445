var searchComponent = {
    searchTypeIds: [],
    dataSources: [],
    setupSearchTypeDatasources: function () {
        var searchTypeIds = $('.SearchBoxAutocomplete-q').data('searchtypes').split('|');
        searchComponent.searchTypeIds = searchTypeIds;
        searchComponent.dataSources = [];

        let lang = document.getElementsByTagName('body')[0].getAttribute('data-ctxlanguage');

        $(searchTypeIds).each(function (i, searchTypeId) {

            var dataSource = new Bloodhound({
                datumTokenizer: function (datum) {
                    return Bloodhound.tokenizers.whitespace(datum.value);
                },
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: "/api/search/category/" + encodeURIComponent(searchTypeId) + "?q=%QUERY&lang=" + lang,
                    wildcard: '%QUERY',
                    filter: function (data) {
                        var searchResultArray = $.map(data.SearchResult,
                            function (item) {
                                return {
                                    Heading: item.Heading,
                                    Url: item.Url,
                                    ImageUrl: item.ImageUrl,
                                    SubHeading: item.SubHeading,
                                    NumberOfHits: data.SearchResult.length,
                                    TotalNumberOfHits: data.TotalNumberOfHits
                                };
                            });
                        return searchResultArray;
                    }
                }
            });
            dataSource.initialize();

            var searchTypeInfo = $("input[data-id='" + searchTypeId + "']");

            searchComponent.dataSources.push({
                searchTypeId: searchTypeId,
                heading: searchTypeInfo.data('label'),
                limit: 'Infinity',
                noHitsText: searchTypeInfo.data('nohits-text'),
                hitsText: searchTypeInfo.data('hits-text'),
                linkText: searchTypeInfo.data('link-text'),
                linkUrl: searchTypeInfo.data('link-url'),
                dataSource: dataSource
            });
        });
    },
    initTypeahead: function () {
        $('.SearchBoxAutocomplete-q').typeahead('destroy'); // Typeahead adds markup for each time the plugin is initialized, so have to destroy it first.

        var options = {
            hint: false,
            minLength: 2,
            classNames: {
                input: 'SearchBoxAutocomplete-typeaheadInput',
                hint: 'SearchBoxAutocomplete-typeaheadHint',
                menu: 'SearchBoxAutocomplete-typeaheadMenu',
                dataset: 'SearchBoxAutocomplete-typeaheadDataset',
                suggestion: 'SearchBoxAutocomplete-typeaheadSuggestion',
                empty: 'SearchBoxAutocomplete-typeaheadEmpty',
                open: 'SearchBoxAutocomplete-typeaheadOpen',
                cursor: 'SearchBoxAutocomplete-typeaheadCursor',
                highlight: 'SearchBoxAutocomplete-typeaheadHighlight'
            }
        };

        var dataSets = [];

        $(searchComponent.dataSources).each(function (i, item) {

            var newDataset = {
                name: i,
                limit: item.limit,
                displayKey: 'Heading',
                source: item.dataSource.ttAdapter(),
                templates: {
                    suggestion: function (data) {
                        var imageUrl = data.ImageUrl;
                        var imageMarkup = "";
                        var urlMarkup = "";
                        var headingMarkup = "";
                        var subHeadingMarkup = "";

                        if (imageUrl && imageUrl.length > 0) {
                            imageMarkup =
                                '<div class="pr-2">' +
                                '<img src="' + imageUrl + '" class="SearchBoxAutocomplete-suggestionImage" />' +
                                '</div>';
                        }

                        if (data.Url) {
                            urlMarkup = data.Url;
                        }

                        if (data.Heading) {
                            headingMarkup = '<strong class="SearchBoxAutocomplete-title u-heading">' + data.Heading + '</strong>';
                        }

                        if (data.SubHeading) {
                            subHeadingMarkup = '<span class="SearchBoxAutocomplete-subHeading">' + data.SubHeading + '</span>';
                        }

                        var html = '<div>' +
                            '<a href="' + urlMarkup + '" class="SearchBoxAutocomplete-autocompleteLink d-flex mb-3">' +
                            imageMarkup +
                            '<div class="SearchBoxAutocomplete-autocompleteTextWrapper">' +
                            headingMarkup +
                            '<br/>' +
                            subHeadingMarkup +
                            '<br>' +
                            '<span class="SearchBoxAutocomplete-autocompleteIntro"></span>' +
                            '</div>' +
                            '</a>' +
                            '</div> ';

                        html += '<input type="hidden" class="hitCountContainer" data-tothits="' + data.TotalNumberOfHits + '" data-shownhits="' + data.NumberOfHits + '"/>';

                        return html;
                    },
                    header: function (data) {
                        var showAllUrl = item.linkUrl + "?c=" + item.searchTypeId + "&q=" + data.query;
                        var showAllLink = data.suggestions[0].TotalNumberOfHits > 8 ? '<a href="' + showAllUrl + '" class="u-heading d-inlineblock ml-4 SearchBoxAutocomplete-showAllLink">' + item.linkText + '</a>' : "";

                        return '<div class="u-heading SearchBoxAutocomplete-autocompleteHeader mb-3">' + item.heading + ' (' + data.suggestions[0].TotalNumberOfHits + ')' + showAllLink + '</div > ';
                    },
                    empty: function (data) {
                        return '<div class="u-heading SearchBoxAutocomplete-autocompleteHeader">' + item.heading + '</div><em>' + item.noHitsText +'</em>';
                    }
                }
            };

            dataSets.push(newDataset);
        });

        $('.SearchBoxAutocomplete-q').typeahead(options, dataSets);
        $('.SearchBoxAutocomplete-q').on('typeahead:selected', function (evt, item) {
            if (item.Url) {
                window.location.href = item.Url;
            }
        });
        $('.SearchBoxAutocomplete-q').on('typeahead:close', function () {
            $('.SearchBoxAutocomplete-q').val("");
        });
    },

    setFocusToDropDownSearchInput: function () {
        $("#search-icon-link").on('click', function () { $(this).parent().find(".SearchBoxAutocomplete-q").focus(); });
    },

    init: function () {
        searchComponent.setFocusToDropDownSearchInput();
        searchComponent.setupSearchTypeDatasources();
        searchComponent.initTypeahead();
    }
};

$(function () {
    searchComponent.init();
});
